<template>
  <div class="image-modal">
    <div v-if="filterCureProblemList.length" class="problem-content">
      <div class="problem-title">
        <img alt="" src="/static/images/createCases/web_preview_tips.png" />
        存在如下问题
      </div>
      <div class="problem-show">
        <div
          v-for="item in filterCureProblemList"
          :key="item.groupNo"
          class="problem-item"
          @click="scrollToCurrent(`question-${item.groupNo}`)"
        >
          {{ `${item.groupNo}.${item.groupName}` }}
        </div>
      </div>
    </div>
    <div id="question-5" class="case-question">
      <div class="case-title">{{ $t("stageAdjust.pdjzqbthbwzp") }}</div>
      <p
        v-if="!!filterCureProblemList.find(it => it.groupNo === 5)"
        class="problem_note"
      >
        {{ filterCureProblemList.find(it => it.groupNo === 5).content }}
      </p>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in notFitPicture"
            :key="index"
            class="upload-img-item"
          >
            <div
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(event, 'notFit', index);
                }
              "
              class="after-upload"
              @click="
                showCarousel({ ...item, name: $t('stageAdjust.bthbwzp') })
              "
            >
              <img
                :src="`${$PicPrefix}${item.nanoId}?imageView2/0/w/360/h/250`"
                :style="{
                  transform: `rotate(${item['degree']}deg) scaleY(${
                    item['vertical'] ? -1 : 1
                  }) scaleX(${item['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                v-show="item.nanoId"
                class="remove_pic"
                @click="e => removeFiledPic(e, item.id, 'notFitPicture')"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
          </div>
          <div v-if="notFitPicture.length < 9" class="upload-img-item">
            <div class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="(res, file) => notFitUploadSuccess(res)"
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p class="up_load_def up_load_def10"></p>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="question-6" class="case-question">
      <div class="case-title required-title">
        {{ $t("cases.createImage.kqnzp") }}
        <div class="link">
          <img
            alt=""
            class="dib mr4"
            src="../../../../../common/imgs/case/creater_img_ask.png"
            style="width: 12px"
          />
          <router-link
            :to="{ name: 'helpCenter', query: { type: '12' } }"
            style="display: flex; align-items: center"
            target="_blank"
          >
            <span class="main_theme_color fz14 curp">{{
              $t("cases.createImage.zppsbz")
            }}</span>
          </router-link>
        </div>
      </div>
      <p
        v-if="!!filterCureProblemList.find(it => it.groupNo === 6)"
        class="problem_note"
      >
        {{ filterCureProblemList.find(it => it.groupNo === 6).content }}
      </p>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in facePic"
            :key="index"
            class="upload-img-item"
          >
            <div
              v-if="resultObj[item.id] && resultObj[item.id].nanoId"
              class="after-upload"
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(
                    event,
                    item.id,
                    $t(`cases.createImage.${item.label}`)
                  );
                }
              "
              @click="showCarousel(item)"
            >
              <img
                v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                :src="
                  `${$PicPrefix}${
                    resultObj[item.id].nanoId
                  }?imageView2/0/w/360/h/250`
                "
                :style="{
                  transform: `rotate(${
                    resultObj[item.id]['degree']
                  }deg) scaleY(${
                    resultObj[item.id]['vertical'] ? -1 : 1
                  }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                class="remove_pic"
                @click="e => removeFiledPic(e, item.id)"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
            <div v-else class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      item.id,
                      $t(`cases.createImage.${item.label}`)
                    )
                "
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p :class="`up_load_def up_load_def${index + 1}`"></p>
              </el-upload>
            </div>
            <div class="img-name require-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="question-7" class="case-question">
      <div class="case-title">
        {{ $t("cases.maintain.jzwchxgp") }}
        <div class="link">
          <img
            alt=""
            class="dib mr4"
            src="../../../../../common/imgs/case/creater_img_ask.png"
            style="width: 12px"
          />
          <router-link
            :to="{ name: 'helpCenter', query: { type: '13' } }"
            style="display: flex; align-items: center"
            target="_blank"
          >
            <span class="fz14 main_theme_color">{{
              $t("cases.createImage.xgpbz")
            }}</span>
          </router-link>
        </div>
      </div>
      <p
        v-if="!!filterCureProblemList.find(it => it.groupNo === 7)"
        class="problem_note"
      >
        {{ filterCureProblemList.find(it => it.groupNo === 7).content }}
      </p>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in XRayPic"
            :key="index"
            class="upload-img-item"
          >
            <div
              v-if="resultObj[item.id] && resultObj[item.id].nanoId"
              class="after-upload"
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(
                    event,
                    item.id,
                    $t(`cases.createImage.${item.label}`)
                  );
                }
              "
              @click="showCarousel(item)"
            >
              <img
                v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                :src="
                  `${$PicPrefix}${
                    resultObj[item.id].nanoId
                  }?imageView2/0/w/360/h/250`
                "
                :style="{
                  transform: `rotate(${
                    resultObj[item.id]['degree']
                  }deg) scaleY(${
                    resultObj[item.id]['vertical'] ? -1 : 1
                  }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                class="remove_pic"
                @click="e => removeFiledPic(e, item.id)"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
            <div v-else class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      item.id,
                      $t(`cases.createImage.${item.label}`)
                    )
                "
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p class="up_load_def up_load_def10"></p>
              </el-upload>
            </div>
            <div class="img-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="question-8" class="case-question">
      <div class="case-title">{{ $t("cases.createImage.yxzl") }}</div>
      <p
        v-if="!!filterCureProblemList.find(it => it.groupNo === 8)"
        class="problem_note"
      >
        {{ filterCureProblemList.find(it => it.groupNo === 8).content }}
      </p>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in otherPictures"
            :key="index"
            class="upload-img-item"
          >
            <div
              class="after-upload"
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(event, 'other', index);
                }
              "
              @click="
                showCarousel({ ...item, name: $t('cases.createImage.yxzl') })
              "
            >
              <img
                :src="`${$PicPrefix}${item.nanoId}?imageView2/0/w/360/h/250`"
                :style="{
                  transform: `rotate(${item['degree']}deg) scaleY(${
                    item['vertical'] ? -1 : 1
                  }) scaleX(${item['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                class="remove_pic"
                @click="e => removeFiledPic(e, item.id, 'otherPicture')"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
          </div>
          <div v-if="otherPictures.length < 10" class="upload-img-item">
            <div class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="(res, file) => otherUploadSuccess(res)"
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p class="up_load_def up_load_def10"></p>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="question-9" class="case-question">
      <div class="case-title required-title">
        {{ $t("cases.maintain.yhmx") }}
      </div>
      <p
        v-if="!!filterCureProblemList.find(it => it.groupNo === 9)"
        class="problem_note"
      >
        {{ filterCureProblemList.find(it => it.groupNo === 9).content }}
      </p>
      <div class="c-q-content">
        <div class="button-filed-item">
          <div
            :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
            class="label"
          >
            {{ $t("cases.createImage.gxjmx") }}
          </div>
          <div class="filed-value">
            <CommonButton
              :disabled="!disabledModel"
              :echoContent="resultObj['siliconeImpression']"
              :renderList="siliconeRubberModel"
              parentKey="siliconeImpression"
              @buttonChange="buttonHandlerChange"
            />
          </div>
        </div>
        <div
          v-if="resultObj['siliconeImpression'] === 'silicagel_print_model'"
          class="print-model"
        >
          <div class="tips">
            {{ $t("cases.createImage.yscblh") }}
          </div>
          <div class="send-express-container">
            <div class="case-number">
              {{ $t("cases.cases.blh") }}：
              <input
                id="caseNumber"
                :value="caseDetail.caseNumber"
                readonly
                type="text"
              />
            </div>
            <div ref="copyCases" class="copy" @click="copyCasesNumber"></div>
            <div class="send" @click="sendExpress">
              <img
                alt=""
                class="express-icon"
                src="/static/images/createCases/pc_doc_shunfeng.png"
              />
              {{ $t("cases.createImage.fkd") }}
            </div>
          </div>
        </div>
        <div class="button-filed-item">
          <div
            :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
            class="label"
          >
            {{ $t("cases.createImage.szmxwz") }}
          </div>
          <div class="filed-value">
            <CommonButton
              :disabled="!disabledModel"
              :echoContent="resultObj['stlFile']"
              :renderList="digitalModelFile"
              parentKey="stlFile"
              @buttonChange="buttonHandlerChange"
            />
          </div>
        </div>
        <div
          v-show="resultObj['stlFile'] === 'local_upload'"
          :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
        >
          <div class="s_c__up">
            {{ $t("cases.createImage.se") }}
            <el-upload
              ref="sharpUpJaw"
              :action="UPLOAD_URL"
              :before-upload="
                file => beforeFileUpload(file, 'sharp_upjaw', 'stl')
              "
              :data="{ token: uploadToken }"
              :disabled="!disabledModel"
              class="upload-file"
              drag
            >
              <span class="up_load">{{ $t("cases.createImage.scstl") }}</span>
            </el-upload>
            <!-- <span
                class="up_icon"
                @click="clickSharpUpJaw('sharpUpJaw')"
              ></span> -->
            <p v-show="fileNameObj['sharp_upjaw']" class="up_file">
              {{ fileNameObj["sharp_upjaw"] }}
              <span class="up_status el-icon-success"></span>
            </p>
          </div>
          <div class="s_c__up">
            {{ $t("cases.createImage.xe") }}
            <el-upload
              ref="sharpDownJaw"
              :action="UPLOAD_URL"
              :before-upload="
                file => beforeFileUpload(file, 'sharp_downjaw', 'stl')
              "
              :data="{ token: uploadToken }"
              :disabled="!disabledModel"
              class="upload-file"
              drag
            >
              <span class="up_load">{{ $t("cases.createImage.scstl") }}</span>
            </el-upload>
            <!-- <span
                class="up_icon"
                @click="clickSharpUpJaw('sharpDownJaw')"
              ></span> -->
            <p v-show="fileNameObj['sharp_downjaw']" class="up_file">
              {{ fileNameObj["sharp_downjaw"] }}
              <span class="up_status el-icon-success"></span>
            </p>
          </div>
        </div>
        <div
          v-show="resultObj['stlFile'] === 'iTero'"
          :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
        >
          <div class="s_c__up">
            {{ $t("cases.createImage.wj") }}
            <el-upload
              ref="iTero"
              :action="UPLOAD_URL"
              :before-upload="file => beforeFileUpload(file, 'iTero', 'zip')"
              :data="{ token: uploadToken }"
              :disabled="!disabledModel"
              class="upload-file"
              drag
            >
              <span class="up_load">{{ $t("cases.createImage.sczip") }}</span>
            </el-upload>
            <p v-show="fileNameObj['iTero']" class="up_file">
              {{ fileNameObj["iTero"] }}
              <span class="up_status el-icon-success"></span>
            </p>
          </div>
        </div>
        <div
          v-show="resultObj['stlFile'] === 'other'"
          :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
        >
          <div class="s_c__up">
            {{ $t("cases.createImage.wj") }}
            <el-upload
              ref="otherFile"
              :action="UPLOAD_URL"
              :before-upload="
                file => beforeFileUpload(file, 'other_file', 'zip', 'other')
              "
              :data="{ token: uploadToken }"
              :disabled="!disabledModel"
              :file-list="fileNameObj['other_file']"
              :limit="10"
              :on-exceed="otherListInit"
              :show-file-list="false"
              class="upload-file"
              drag
              multiple
            >
              <span class="up_load">{{ $t("cases.createImage.sczip") }}</span>
            </el-upload>
            <ul
              v-show="
                fileNameObj['other_file'] && fileNameObj['other_file'].length
              "
            >
              <li
                v-for="(item, index) in fileNameObj['other_file']"
                :key="index"
                class="up_file other_file"
              >
                {{ item.name }}
                <span class="up_status el-icon-success"></span>
                <span
                  v-show="disabledModel"
                  class="up_status el-icon-close curp"
                  @click="deleteOtherFile(index, item.id)"
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ExBirdie
      ref="exBirdie"
      :caseDetail="caseDetail"
      :defaultKey="exDefaultKey"
      refType="1"
      @tabsChange="tabsChanged"
    />
    <PreviewImg
      ref="previewImg"
      :carouselList="carouselList"
      @imgChange="imgChange"
    />
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import * as QINIU from "qiniu-js";
import { nanoid } from "nanoid";
import { notifyMsg } from "common/js/util";
import { UPLOAD_URL } from "common/js/config";
import { getQiniuToken } from "common/api/public";
import { mapState } from "vuex";
import { filter, map, pickBy, extend } from "lodash";
import ExBirdie from "components/expressage_birdie/index";
import FullLoading from "components/full-loading/full-loading";
import PreviewImg from "components/preview-image/preview-image.vue";
import CommonButton from "../../casesChildren/components/common-button.vue";
import { columnDataTempRecordDetail } from "common/api/cases";

export default {
  props: ["caseOptions", "caseDetail", "questionName", "cureProblemList"],
  components: {
    CommonButton,
    ExBirdie,
    PreviewImg,
    FullLoading
  },
  data() {
    return {
      nanoid: nanoid(),
      uploadToken: "",
      resultObj: {},
      UPLOAD_URL,
      dentalModelDisable: false,
      exDefaultKey: "",
      carouselList: [],
      otherPictures: [],
      notFitPicture: [],
      isLoading: false,
      idxObj: {
        sideProfile: 0,
        frontal: 1,
        frontalSmile: 2,
        upperArch: 3,
        anteriorOverjet: 4,
        lowerArch: 5,
        intraOralRight: 6,
        intraOralFrontal: 7,
        intraOralLeft: 8,
        panoramicRadiograph: 9,
        lateralCephalogram: 10
      },
      fileNameObj: {
        other_file: [],
        iTero: "",
        sharp_upjaw: "",
        sharp_downjaw: ""
      },
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx"
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx"
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx"
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx"
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx"
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx"
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx"
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx"
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx"
        }
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp"
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp"
        }
      ],
      siliconeRubberModel: [
        {
          key: "silicagel_print_model",
          value: this.$t("yssh.smqj")
        }
      ],
      digitalModelFile: [
        {
          key: "local_upload",
          value: this.$t("yssh.bdsc"),
          enValue: "Upload"
        },
        {
          key: "iTero",
          value: this.$t("yssh.itero"),
          enValue: "iTero"
        },
        {
          key: "other",
          value: this.$t("yssh.qt"),
          enValue: "Others"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    disabledModel() {
      if (!this.cureProblemList.length) {
        return true;
      } else {
        return !!this.cureProblemList.find(it => it.groupNo === 9);
      }
    },
    filterCureProblemList() {
      return this.cureProblemList.filter(item =>
        ["2", "3"].includes(item.groupType)
      );
    }
  },
  methods: {
    handleDrop(event, filed, name) {
      const file = event.dataTransfer.files[0];
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        // this.isLoading = true;
        this.beforeDrop(file, filed, name);
      }
    },
    async beforeDrop(file, filed, name) {
      const params = await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.uploadToken);
        formData.append("key", nanoid());
        const fileName = file.name.replace(/\s/g, ""); // 去除空格
        const ts = new Date().getTime();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 根据组件 api 返回固定格式的 url
            const response = JSON.parse(xhr.responseText);
            // const alt = response.key;
            resolve(response);
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      if (filed === "notFit") {
        this.notFitUploadSuccess(params, name + 1);
      } else if (filed === "other") {
        this.otherUploadSuccess(params, name + 1);
      } else {
        this.carouselList = this.carouselList.filter(v => v?.filed !== filed);
        this.uploadSuccess(params, filed, name);
      }
    },
    initCurrentList() {
      let faceList = [];
      this.facePic.filter(item =>
        faceList.push({ name: item.name, ...this.resultObj[item.id] })
      );
      let xRayList = [];
      this.XRayPic.filter(item =>
        xRayList.push({ name: item.name, ...this.resultObj[item.id] })
      );
      const list = [
        ...this.notFitPicture,
        ...faceList,
        ...xRayList,
        ...this.otherPictures
      ];
      this.carouselList = list.map((item, index) => {
        return {
          ...item,
          index
        };
      });
    },
    modifyData() {
      const otherPic = map(this.otherPictures, it => {
        return {
          nanoId: it.nanoId,
          ETag: it.ETag,
          degree: it.degree || 0,
          horizontal: it.horizontal || false,
          vertical: it.vertical || false
        };
      });
      const notFitPicture = map(this.notFitPicture, it => {
        return {
          nanoId: it.nanoId,
          ETag: it.ETag,
          degree: it.degree || 0,
          horizontal: it.horizontal || false,
          vertical: it.vertical || false
        };
      });
      this.resultObj = {
        ...this.resultObj
      };
      if (otherPic.length) {
        this.resultObj["otherPicture"] = JSON.stringify(otherPic);
      } else {
        delete this.resultObj["otherPicture"];
      }
      if (notFitPicture.length) {
        this.resultObj["notFitPicture"] = JSON.stringify(notFitPicture);
      } else {
        delete this.resultObj["notFitPicture"];
      }
      let resultObj = JSON.parse(JSON.stringify(this.resultObj));
      resultObj["sideProfile"] =
        resultObj["sideProfile"] && JSON.stringify(resultObj["sideProfile"]);
      resultObj["frontal"] =
        resultObj["frontal"] && JSON.stringify(resultObj["frontal"]);
      resultObj["frontalSmile"] =
        resultObj["frontalSmile"] && JSON.stringify(resultObj["frontalSmile"]);
      resultObj["upperArch"] =
        resultObj["upperArch"] && JSON.stringify(resultObj["upperArch"]);
      resultObj["anteriorOverjet"] =
        resultObj["anteriorOverjet"] &&
        JSON.stringify(resultObj["anteriorOverjet"]);
      resultObj["lowerArch"] =
        resultObj["lowerArch"] && JSON.stringify(resultObj["lowerArch"]);
      resultObj["intraOralRight"] =
        resultObj["intraOralRight"] &&
        JSON.stringify(resultObj["intraOralRight"]);
      resultObj["intraOralFrontal"] =
        resultObj["intraOralFrontal"] &&
        JSON.stringify(resultObj["intraOralFrontal"]);
      resultObj["intraOralLeft"] =
        resultObj["intraOralLeft"] &&
        JSON.stringify(resultObj["intraOralLeft"]);
      resultObj["panoramicRadiograph"] =
        resultObj["panoramicRadiograph"] &&
        JSON.stringify(resultObj["panoramicRadiograph"]);
      resultObj["lateralCephalogram"] =
        resultObj["lateralCephalogram"] &&
        JSON.stringify(resultObj["lateralCephalogram"]);
      return pickBy(resultObj);
    },
    getRecordDetail() {
      this.isLoading = true;
      const { caseId, curePlanId } = this.$route.query;
      columnDataTempRecordDetail({
        caseId,
        curePlanId,
        type: "phase_adjustment"
      }).then(res => {
        this.resultObj = res["columnData"];
        this.facePic.forEach(item => {
          if (item.id in this.resultObj) {
            this.resultObj[item.id] = {
              id: item.id,
              ...this.isJSON(this.resultObj[item.id])
            };
          }
        });
        this.XRayPic.forEach(item => {
          if (item.id in this.resultObj) {
            this.resultObj[item.id] = {
              id: item.id,
              ...this.isJSON(this.resultObj[item.id])
            };
          }
        });

        if (this.resultObj?.["otherPicture"]) {
          const otherPicture = (this.resultObj["otherPicture"] = this.isJSON(
              this.resultObj["otherPicture"]
          )).filter(Boolean)||[]
          this.otherPictures = otherPicture?.map((item, index) => {
            return {
              ...item,
              id: "otherPicture" + index
            };
          });
        }
        if (this.resultObj["notFitPicture"]) {
          const notFitPicture = (this.resultObj["notFitPicture"] = this.isJSON(
            this.resultObj["notFitPicture"]
          ));
          this.notFitPicture = notFitPicture.map((item, index) => {
            return {
              ...item,
              id: "notFitPicture" + index,
              name: this.$t("stageAdjust.bthbwzp")
            };
          });
        }
        this.initCurrentList();
        const { attachmentList = [] } = res["columnData"];
        if (attachmentList) {
          this.resultObj.attachmentList = attachmentList;
        }
        attachmentList.forEach(item => {
          if (item.fileKey !== "other_file") {
            this.fileNameObj[item.fileKey] = item.fileName;
          } else {
            this.fileNameObj["other_file"].push({
              name: item.fileName,
              id: item.id
            });
          }
        });
        this.isLoading = false;
        if (this.questionName !== "") {
          let questionFix;
          switch (this.questionName) {
            case this.$t("stageAdjust.kqnzp"):
              questionFix = "question-6";
              break;
            case this.$t("stageAdjust.yhmx"):
              questionFix = "question-9";
              break;
          }
          this.scrollToCurrent(questionFix);
        } else {
          const parent = document.querySelector(".stage-adjustment");
          parent.scrollTo({
            top: 0
          });
        }
      });
    },
    scrollToCurrent(current) {
      const parent = document.querySelector(".stage-adjustment");
      const child = document.querySelector(`#${current}`);
      parent.scrollTo({
        top: child.offsetTop - 100,
        behavior: "smooth"
      });
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false
          };
        }
      }
    },
    otherUploadSuccess(e, i) {
      const { key, hash } = e;
      const filed = "other" + (this.otherPictures.length + 1);
      const current = {
        nanoId: key,
        name: this.$t("cases.createImage.yxzl"),
        ETag: key,
        href: this.$PicPrefix + hash,
        filed,
        id: filed
      };
      if (i) {
        this.otherPictures[i - 1] = current;
      } else {
        this.otherPictures.push(current);
      }
      this.otherPictures = this.otherPictures.map((item, index) => {
        return {
          ...item,
          id: "otherPictures" + index
        };
      });
      this.initCurrentList();
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    notFitUploadSuccess(e, i) {
      const { key, hash } = e;
      const filed = "notFit" + (this.notFitPicture.length + 1);
      const current = {
        nanoId: key,
        name: "不贴合部位照片",
        ETag: key,
        href: this.$PicPrefix + hash,
        filed,
        id: filed
      };
      if (i) {
        this.notFitPicture[i - 1] = current;
      } else {
        this.notFitPicture.push(current);
      }
      this.notFitPicture = this.notFitPicture.map((item, index) => {
        return {
          ...item,
          id: "notFitPicture" + index
        };
      });
      this.initCurrentList();
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    otherListInit() {
      notifyMsg(this, "warning", this.$t("cases.createImage.zdxz"));
    },
    sendExpress() {
      if (!this.disabledModel) return;
      this.exDefaultKey = "silicagel_print_model";
      this.$refs["exBirdie"].openDia().then(() => {
        this.$refs["exBirdie"] &&
          this.$refs["exBirdie"].resetClinic(this.caseDetail.clinicId);
        this.$emit("stagingData");
      });
    },
    tabsChanged(key) {
      this.exDefaultKey = key;
      this.resultObj["siliconeImpression"] = key;
    },
    buttonHandlerChange(val, parentKey) {
      this.$forceUpdate();
      if (parentKey === "siliconeImpression") {
        (this.fileNameObj = {
          other_file: [],
          iTero: "",
          sharp_upjaw: "",
          sharp_downjaw: ""
        }),
          this.$delete(this.resultObj, "stlFile");
      }
      if (parentKey === "stlFile") {
        this.$delete(this.resultObj, "siliconeImpression");
      }
      if (this.resultObj["stlFile"] !== val["stlFile"]) {
        this.fileNameObj = {
          other_file: [],
          iTero: "",
          sharp_upjaw: "",
          sharp_downjaw: ""
        };
        this.$set(this.resultObj, "attachmentList", []);
      }
      this.resultObj = Object.assign(this.resultObj, val);
      if (val["siliconeImpression"] === "self_deliver") {
        this.exDefaultKey = "self_deliver";
        this.$refs["exBirdie"].openDia().then(() => {
          this.$refs["exBirdie"] &&
            this.$refs["exBirdie"].resetClinic(this.caseDetail.clinicId);
          this.$emit("stagingData");
        });
      }
    },
    removeFiledPic(event, filed, type) {
      event.stopPropagation();
      if (type === "otherPicture") {
        const currentIdx = this.otherPictures.findIndex(
          item => item.filed === filed
        );
        this.otherPictures.splice(currentIdx, 1);
      } else if (type === "notFitPicture") {
        const currentIdx = this.notFitPicture.findIndex(
          item => item.filed === filed
        );
        this.notFitPicture.splice(currentIdx, 1);
      } else {
        const { resultObj } = this;
        this.resultObj = {
          ...resultObj,
          [filed]: ""
        };
      }
      this.initCurrentList();
    },
    beforeFileUpload(file, key, type) {
      const { name } = file;
      const _this = this;
      const filedTypeFilter = type === "zip" ? ["zip", "rar"] : ["stl"];
      const fileType = name?.split(".").pop();
      if (filedTypeFilter.includes(fileType)) {
        this.uploading = true;
        const { attachmentList } = _this.resultObj;
        const list =
          key !== "other_file"
            ? attachmentList.filter(item => item.fileKey !== key)
            : attachmentList;
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + "_" + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            _this.uploading = false;
          },
          complete(res) {
            const { hash } = res;
            _this.uploading = false;
            _this.resultObj.attachmentList = [
              ...list,
              {
                fileKey: key === "iTero" ? "iTero" : key,
                fileName: ts + "_" + name,
                realFileName: name,
                fileType: type,
                fileUrl: hash,
                fileSource: "DOCTOR"
              }
            ];
            if (key === "other_file") {
              if (!_this.fileNameObj[key]) {
                _this.$set(_this.fileNameObj, key, [{ name: ts + "_" + name }]);
              } else {
                _this.$set(_this.fileNameObj, key, [
                  ..._this.fileNameObj[key],
                  { name: ts + "_" + name }
                ]);
              }
            } else {
              _this.fileNameObj[key === "iTero" ? "iTero" : key] =
                ts + "_" + name;
            }
            notifyMsg(_this, "success", _this.$t("common.message.wjsccg"));
          }
        });
      } else {
        notifyMsg(
          this,
          "warning",
          `${this.$t("common.message.qxz")}${type}${this.$t(
            "common.message.wjsc"
          )}`
        );
      }
      return false;
    },
    uploadSuccess(res, filed, name) {
      this.$forceUpdate();
      this.uploading = false;
      const { resultObj } = this;
      resultObj[filed] = { ETag: res.key, nanoId: res.key, id: filed };
      this.initCurrentList();
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        return true;
      }
    },
    uploadError() {
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    },
    initObj(str, filed, name) {
      if (str == "") {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.resultObj) {
        if (obj.filed === key) {
          const current = this.resultObj[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    showCarousel(val) {
      const current = this.carouselList.find(item => val.id === item.id);
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(val.name, current.index);
      });
    },
    deleteOtherFile(index, id) {
      this.fileNameObj["other_file"].splice(index, 1);
      this.resultObj.attachmentList = filter(
        map(this.resultObj.attachmentList, item => {
          if (item.id === id) {
            return;
          }
          return item;
        })
      );
    },
    getPicIndex(filed) {
      return filter(this.carouselList, item => item.filed === filed)[0].index;
    },
    copyCasesNumber() {
      const input = document.querySelector("#caseNumber");
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        notifyMsg(this, "success", this.$t("cases.createImage.fzcg"));
      }
    }
  },
  mounted() {
    this.getRecordDetail();
    if (
      this.caseDetail.hasOwnProperty("selfDeliverFlag") &&
      this.caseDetail.selfDeliverFlag == "1"
    ) {
      this.siliconeRubberModel = [
        ...this.siliconeRubberModel,
        {
          key: "self_deliver",
          value: this.$t("yssh.zxyj")
        }
      ];
    }
    getQiniuToken().then(data => {
      this.uploadToken = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.image-modal {
  padding: 0 60px 64px;

  .problem-content {
    margin-top: 30px;
    padding: 13px 16px;
    background-color: #f4f4f4;
    border-radius: 4px;

    .problem-title {
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 10px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .problem-show {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      line-height: 22px;

      .problem-item {
        cursor: pointer;
        margin-right: 20px;
        transition: linear 0.2s;

        &:hover {
          color: #fcc80e;
        }
      }
    }
  }

  .case-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 26px;
    color: #333;
    margin: 20px 0 10px;

    .link {
      display: flex;
      align-items: center;
      color: #fcc80e;
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .required-title {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ed4027;
      margin-right: 4px;
    }
  }

  .button-filed-item {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 20px;
      margin-right: 20px;
      white-space: nowrap;
    }
  }

  .print-model {
    margin-bottom: 30px;

    .tips {
      font-size: 14px;
      line-height: 20px;
      color: #666;
      margin-bottom: 10px;
    }
  }

  .send-express-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    height: 68px;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 16px 20px;
    color: #333;
    font-size: 14px;
    line-height: 20px;

    .copy {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: url("../../../../../common/imgs/case/case_icon_plus.png")
        no-repeat center;
    }

    .send {
      .express-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }

      cursor: pointer;
      padding: 0 12px;
      height: 36px;
      border-radius: 4px;
      background-color: #fcc80e;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }
  }

  .upload-tips {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .up_load_def {
    position: absolute;
    left: 0;
    top: 0.5rem;
    right: 0;
    height: 1rem;
    z-index: 9;
    background-size: 100% 100%;
  }

  .s_c__up {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 0.16rem;
    font-size: 14px;
    color: $main_theme_color_333;

    .upload-file {
      margin: 0 0.2rem;
    }

    .up_load {
      display: inline-block;
      height: 0.4rem;
      line-height: 0.4rem;
      margin: 0 0.16rem;
      border-radius: 0.03rem;
      outline: none;
      padding: 0 0.2rem;
      position: relative;
    }

    .up_icon {
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background-image: url("/static/images/createCases/web_doc_upload.png");
      background-size: 100% 100%;
      cursor: pointer;
    }

    .up_status {
      color: #5cb6ff;
    }

    .up_file {
      display: flex;
      align-items: center;
      margin-left: 0.2rem;

      .up_status {
        color: #5cb6ff;
        margin-left: 0.1rem;
      }
    }

    .other_file {
      margin-bottom: 0.1rem;
    }
  }

  .up_load_def1 {
    background-image: url("/static/images/createCases/side.png");
  }

  .up_load_def2 {
    background-image: url("/static/images/createCases/front.png");
  }

  .up_load_def3 {
    background-image: url("/static/images/createCases/smile.png");
  }

  .up_load_def4 {
    background-image: url("/static/images/createCases/top.png");
  }

  .up_load_def5 {
    background-image: url("/static/images/createCases/qyfgx.png");
    background-repeat: no-repeat;
  }

  .up_load_def6 {
    background-image: url("/static/images/createCases/bottom.png");
  }

  .up_load_def7 {
    background-image: url("/static/images/createCases/right.png");
  }

  .up_load_def8 {
    background-image: url("/static/images/createCases/main.png");
  }

  .up_load_def9 {
    background-image: url("/static/images/createCases/left.png");
  }

  .up_load_def10 {
    background-image: url("/static/images/createCases/select.png");
    background-repeat: no-repeat;
  }

  .up_load_def11 {
    width: 92px;
    height: 52px;
    left: 50%;
    top: 82px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/yhx.png");
    background-repeat: no-repeat;
  }

  .up_load_def12 {
    width: 74px;
    height: 67px;
    left: 50%;
    top: 73px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/khx.png");
    background-repeat: no-repeat;
  }

  .upload-img-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .upload-img-item {
      min-width: 280px;
      max-width: 340px;
      min-height: 240px;
      max-height: 280px;
      margin: 0 43px 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n + 3) {
        margin-right: 0px;
      }

      .after-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        border: 1px dashed #d9d9d9;
        border-radius: 8px;
        background-color: #f0f0f0;
        position: relative;
        overflow: hidden;

        &:hover .pic_look {
          opacity: 1;
        }

        .remove_pic {
          position: absolute;
          right: 0.1rem;
          top: 0.1rem;
          width: 0.3rem;
          height: 0.3rem;
          z-index: 9;
          cursor: pointer;
          border-radius: 100%;
          background-size: 100% 100%;
          background-image: url("/static/images/createCases/delet_img.png");
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .pic_look {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.16rem;
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 1;

          img {
            width: 0.24rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }
      }

      .before-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        border: 1px solid #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;

        .up_box {
          width: 3.44rem;
          height: 240px;
          position: relative;
          background: #f0f0f0;

          .up_box_tip {
            width: 100%;
            position: absolute;
            bottom: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $main_theme_color_333;

            .up___tip_icon {
              display: inline-block;
              width: 0.25rem;
              height: 0.25rem;
              background-size: 100% 100%;
              background-image: url("/static/images/createCases/add_upload_img.png");
              border-radius: 100%;
              margin-right: 0.1rem;
            }
          }
        }
      }

      .img-name,
      .require-name {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: center;
        margin-top: 10px;
      }

      .require-name {
        &::before {
          content: "·";
          font-size: 64px;
          margin-bottom: 10px;
          margin-right: 12px;
          color: #ed4027;
        }
      }
    }
  }
}

.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  line-height: 0.22rem;
  font-size: 0.14rem;
}
</style>
